import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { RequireText } from '@/components/atoms/require-text'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { SelectableTimeSelectionType } from '@/models/self-checkin/custom-checkin'
import { StockPerReservationType } from '@/models/custom-checkin-time-selection'
import { SearchReservationTypeWhenCheckedIn, SearchReservationTypeWhenNonCheckIn } from '@/models/reservation'

interface TimeSelectionProps {
  timeSelection: SelectableTimeSelectionType
  onChange: (params: { timeSelectionId: string; selectedTimes: { time: string; day: string }[] }) => void
  selectedTimes: { time: string; day: string }[]
  setIsValidate: (valid: boolean) => void
  selectedReservations: SearchReservationTypeWhenNonCheckIn[] | SearchReservationTypeWhenCheckedIn[]
}

export const TimeSelection: React.FC<TimeSelectionProps> = ({
  timeSelection,
  onChange,
  selectedTimes,
  setIsValidate,
  selectedReservations,
}) => {
  const { t, i18n } = useTranslation()
  const [radioItems, setRadioItems] = useState<{ [date: string]: Array<{ value: string; label: string; disabled: boolean }> }>()

  const onChangeTime = (e: React.ChangeEvent<HTMLInputElement>, day: string) => {
    const storedIndex = selectedTimes.findIndex(val => val.day === day)
    let cloneSelectedTimes = JSON.parse(JSON.stringify(selectedTimes))
    if (storedIndex === -1) {
      cloneSelectedTimes = [...cloneSelectedTimes, { day, time: e.target.value }]
    } else {
      cloneSelectedTimes[storedIndex].time = e.target.value
    }
    onChange({ timeSelectionId: timeSelection.selection.id, selectedTimes: cloneSelectedTimes })
  }

  const timeToMinutes = (time: string | undefined, intervalMinute: number = 15) => {
    return time ? Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]) : intervalMinute
  }

  const computeSelectableTimes = (date: string) => {
    let nextUsageTime: string
    return Object.keys(timeSelection.mergeAvailabilityTime[date])
      .filter((time, i) => {
        // 開始時間間隔外をフィルター
        const addMinute = timeToMinutes(undefined, timeSelection.selection.usageInterval)
        if (i === 0 || timeToMinutes(nextUsageTime) <= timeToMinutes(time)) {
          nextUsageTime = dayjs(`${date} ${time}`).add(addMinute, 'minute').format('HH:mm')
          return true
        }
        return false
      })
      .filter(time => {
        // 終了時間が枠外であるときにフィルター
        const minUsageMin = timeToMinutes(timeSelection.selection.minUsageTime, timeSelection.selection.usageInterval)
        const endTime = dayjs(`${date} ${time}`)
          .add(minUsageMin - 15, 'minute')
          .format('HH:mm')
        return Object.keys(timeSelection.mergeAvailabilityTime[date]).includes(endTime)
      })
  }

  const isDisabledStartTime = (date: string, time: string): boolean => {
    const usageNumTotal = timeSelection.reservationsByTime[date].reduce((resp, reserveId) => {
      if (timeSelection.selection.stockPerReservation === StockPerReservationType.NumOfPeople) {
        return (resp += selectedReservations?.find(r => r.reservationId === reserveId)?.paxTotal || 1)
      } else {
        return (resp += 1)
      }
    }, 0)

    const minUsageIntervalMinutes = timeToMinutes(timeSelection.selection.minUsageTime, timeSelection.selection.usageInterval)
    const endTime = dayjs(`${date} ${time}`).add(minUsageIntervalMinutes, 'minute').format('HH:mm')
    let runningTime = time
    do {
      if (timeSelection.mergeAvailabilityTime[date][runningTime] < usageNumTotal) {
        return true
      }
      runningTime = dayjs(`${date} ${runningTime}`).add(15, 'minute').format('HH:mm')
    } while (endTime !== runningTime)
    return false
  }

  const makeRadioItems = (date: string) =>
    computeSelectableTimes(date).map(time => ({
      value: time,
      label: time,
      disabled: isDisabledStartTime(date, time),
    }))

  const hasEnabledRadioItems = (_radioItems: Array<{ value: string; label: string; disabled: boolean }>): boolean => {
    return _radioItems.some(item => !item.disabled)
  }

  const validateSelection = (): boolean => {
    if (!timeSelection.selection.isRequired) {
      return true
    }
    const enabledDateNum = Object.keys(radioItems || {}).filter(date => hasEnabledRadioItems(radioItems![date])).length
    return enabledDateNum === selectedTimes.length
  }

  const getTargetGuestName = (date: string): string => {
    if ((selectedReservations?.length || 0) < 2) {
      return ''
    }
    return timeSelection.reservationsByTime[date]
      .map(reserveId => {
        const reserve = selectedReservations.find(r => r.reservationId === reserveId)
        return t('honorific', { name: reserve?.guestName || reserve?.userName || '-' })
      })
      .join(', ')
  }

  useEffect(() => {
    const _radioItems = {}
    let cloneSelectedTimes = JSON.parse(JSON.stringify(selectedTimes))
    Object.keys(timeSelection.mergeAvailabilityTime).map(date => {
      const items = makeRadioItems(date)
      Object.assign(_radioItems, { [date]: items })
      items
        .filter(item => item.disabled)
        .forEach(({ value: time }) => {
          const hasDisabledSelectItem = selectedTimes.some(selectedItem => selectedItem.day === date && selectedItem.time === time)
          if (!hasDisabledSelectItem) {
            return
          }
          cloneSelectedTimes = cloneSelectedTimes.filter(prev => prev.day !== date || prev.time !== time)
        })
    })
    setRadioItems(_radioItems)
    onChange({
      timeSelectionId: timeSelection.selection.id,
      selectedTimes: cloneSelectedTimes,
    })
  }, [])

  useEffect(() => {
    setIsValidate(validateSelection())
  }, [selectedTimes])

  return (
    <div key={timeSelection.selection.title} css={timeSelectionStyle}>
      <h2 className="title">
        {timeSelection.selection.title[i18n.language]} {timeSelection.selection.isRequired && <RequireText />}
      </h2>
      <p className="description" dangerouslySetInnerHTML={{ __html: timeSelection.selection.description[i18n.language] }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
        {Object.keys(radioItems || {}).map((date, i) => (
          <div key={`ts-at-${i}`}>
            <h3 className="sub-title">
              {dayjs(date).format(t('MM-DD(ddd)'))} {getTargetGuestName(date)}
            </h3>
            <RadioGroupField
              value={selectedTimes.find(time => time.day === date)?.time}
              items={radioItems![date]}
              itemCss={css({ padding: '15px 24px' })}
              itemWrapperCss={css({
                border: '1px solid #CCCCCC',
                borderRadius: 10,
                fontSize: 21,
              })}
              groupCss={{ gap: 24 }}
              style={{ direction: 'wrap' }}
              onChange={e => onChangeTime(e, date)}
              error={!hasEnabledRadioItems(radioItems![date]) ? t('SelfCheckIn.There is no availability') : ''}
              customErrorStyle={css({ fontSize: 12 })}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const timeSelectionStyle = css({
  '.title': {
    marginBottom: 16,
    fontSize: 18,
    fontWeight: 'bold',
  },
  '.description': {
    marginBottom: 32,
    lineHeight: 1.5,
  },
  '.sub-title': {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
