import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { SelectableTimeSelectionType, SelectedTime, SpaceAssign } from '@/models/self-checkin/custom-checkin'
import { isEmpty } from 'lodash'
import { TimeSelection } from '@/components/organisms/self-checkin/time-selection'
import { StockPerReservationType } from '@/models/custom-checkin-time-selection'
import dayjs from 'dayjs'

type Props = {
  timeSelections: SelectableTimeSelectionType[]
  setIsRequiredTimeSelection: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedTimes: React.Dispatch<React.SetStateAction<SelectedTime>>
  setAssignSpaces: React.Dispatch<React.SetStateAction<SpaceAssign[]>>
}

export const TimeSelectionForm: React.FC<Props> = ({ timeSelections, ...props }) => {
  const { state } = useSelfCheckInState()
  const [selectedTimes, setSelectedTimes] = useState<SelectedTime>(state.checkinCustomize ? state.checkinCustomize.selectedTime : {})
  const [validateTimeSelections, setValidateTimeSelections] = useState<{ [id: string]: boolean }>({})

  const changeSpaceTime = (params: { timeSelectionId: string; selectedTimes: { time: string; day: string }[] }) => {
    setSelectedTimes(prev => ({ ...prev, [params.timeSelectionId]: params.selectedTimes }))
  }

  const convertTimeToMinutes = timeStr => {
    const [hours, minutes] = timeStr.split(':').map(Number)
    return hours * 60 + minutes
  }

  useEffect(() => {
    const assignSpaces: SpaceAssign[] = []
    Object.keys(selectedTimes).forEach(timeSelectionId => {
      const timeSelection = timeSelections.find(timeSelection => timeSelection.selection.id === timeSelectionId)
      if (!timeSelection || isEmpty(selectedTimes[timeSelectionId])) {
        return
      }

      selectedTimes[timeSelectionId].forEach(({ day, time }) => {
        timeSelection.reservationsByTime[day]?.forEach(reserveId => {
          const reservation = state?.selectReservation?.selectedReservations.find(reservation => reservation.reservationId === reserveId)
          if (!reservation) {
            return
          }
          const usageFrom = dayjs(`${day} ${time}`)
          const usageTo = timeSelection.selection.minUsageTime
            ? usageFrom.add(convertTimeToMinutes(timeSelection.selection.minUsageTime), 'minute')
            : usageFrom.add(15, 'minute')

          const spaceUsageParams: SpaceAssign = {
            pax: reservation.paxTotal,
            reservationUserName: reservation.guestName || reservation.userName || '',
            reservationId: reserveId,
            spaceId: timeSelection.selection.spaceId,
            usageFrom: usageFrom.format('YYYY-MM-DD HH:mm:ss'),
            usageTo: usageTo.format('YYYY-MM-DD HH:mm:ss'),
            date: day,
            time,
          }

          if (timeSelection.selection.stockPerReservation === StockPerReservationType.Single) {
            assignSpaces.push(spaceUsageParams)
          } else {
            // single でない場合は人数分予約枠を作成
            for (let i = 0; i < reservation.paxTotal; i++) {
              assignSpaces.push(spaceUsageParams)
            }
          }
        })
      })
    })

    props.setSelectedTimes(selectedTimes)
    props.setAssignSpaces(assignSpaces)
  }, [selectedTimes, timeSelections])

  useEffect(() => {
    const isRequiredTimeSelection = Object.keys(validateTimeSelections).some(id => !validateTimeSelections[id])
    props.setIsRequiredTimeSelection(isRequiredTimeSelection)
  }, [validateTimeSelections])

  return (
    <>
      {!isEmpty(timeSelections) && (
        <div css={timeSelectionStyle}>
          {timeSelections.map(timeSelection => (
            <React.Fragment key={`ts-${timeSelection.selection.id}`}>
              <TimeSelection
                timeSelection={timeSelection}
                onChange={changeSpaceTime}
                selectedTimes={selectedTimes[timeSelection.selection.id] || []}
                setIsValidate={isValid => {
                  setValidateTimeSelections(prev => ({ ...prev, [timeSelection.selection.id]: isValid }))
                }}
                selectedReservations={state.selectReservation!.selectedReservations}
              />
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  )
}

const timeSelectionStyle = css({
  maxWidth: 874,
  padding: 40,
  marginInline: 'auto',
  marginTop: 24,
  background: '#fff',
  borderRadius: 10,
  '> div:not(:first-of-type)': {
    marginTop: 40,
    paddingTop: 40,
    borderTop: '1px solid #F2F2F2',
  },
})
