import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import parse from 'html-react-parser'
import 'dayjs/locale/ja'
import { SelfCheckinHeader, FooterAgreement } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { Loading } from '@/components/molecules/self-checkin/loading'
import { fetchCheckinReservationsAndSales, fetchReservationsAndSales } from '@/apis/aipass'
import { OccupationType, isEmployee } from '@/models/self-checkin/accommodation-info'
import { getTranslateOccupation } from '@/libs/self-checkin'
import { useGuestAppUrl } from '@/hooks/use-guest-app-url'
import { RequireText } from '@/components/atoms/require-text'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { SelfCheckInState } from '@/models/self-checkin'
import { SelectableTimeSelectionType } from '@/models/self-checkin/custom-checkin'
import dayjs from 'dayjs'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

export const Index: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeliveryConfirm, setIsDeliveryConfirm] = useState<boolean>(true)
  const [isNeedPaymentSelect, setIsNeedPaymentSelect] = useState<boolean>(false)
  const [isFetchedServer, setIsFetchedServer] = useState<boolean>(false)
  const [isInitializeComponent, setIsInitializeComponent] = useState(false)

  const { state, saveState, doCheckIn } = useSelfCheckInState()
  const { mypageUrl, homePageUrl } = useGuestAppUrl({
    hotelId: state.hotelId,
    plugin: state.setting.plugin,
  })
  const [paymentInfo, setPaymentInfo] = useState<Required<SelfCheckInState>['confirm']['payment']>()
  const history = useHistory()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const accompanyData = () => {
    if (state.accommodationInfo) {
      return state.accommodationInfo.accompany.filter(accompany => accompany?.name.trim())
    }
    return []
  }

  const onClickCheckin = async () => {
    const nextState = {
      ...state,
      confirm: {
        isDeliveryConfirm: state.selectReservation?.hasSmartCheckin ? undefined : isDeliveryConfirm,
        payment: paymentInfo,
      },
    }
    if (isNeedPaymentSelect) {
      saveState(nextState)
      history.push({ pathname: '/self-checkin/payment' })
      return
    }

    try {
      setIsLoading(true)
      await doCheckIn({
        state: nextState,
        language,
        guestAppUrl: { mypageUrl, homePageUrl },
        selectedPaymentMethod: 'DIRECT',
      })
      history.replace({ pathname: '/self-checkin/issue-room-key' })
    } catch (e) {
      console.error(e)
      alert(t('Please complete the procedure at the front desk'))
      history.push({ pathname: `/self-checkin`, search: `?hotelId=${state.hotelId}` })
    } finally {
      setIsLoading(false)
    }
  }

  const goEditPage = (anchor: string) => {
    saveState({
      ...state,
      confirm: {
        isDeliveryConfirm: state.selectReservation?.hasSmartCheckin ? undefined : isDeliveryConfirm,
        payment: paymentInfo,
      },
    })
    history.push({ pathname: '/self-checkin/info/edit', state: { anchor } })
  }

  const getTitle = guide => {
    if (language === 'ja') {
      return parse(guide.jpTitle)
    }
    if (language === 'en') {
      return parse(guide.enTitle)
    }
    if (language === 'ko') {
      return parse(guide.koTitle)
    }
    if (language === 'zh') {
      return parse(guide.zhTitle)
    }
  }
  const getTimeSelection = (timeSelectionId: string): SelectableTimeSelectionType => {
    return state.checkinCustomize?.timeSelections.find(({ selection }) => selection.id === timeSelectionId) as SelectableTimeSelectionType
  }

  const _isOtherOccupation = (): boolean => {
    return state.accommodationInfo?.occupation === OccupationType.Other
  }

  const getTargetGuestName = (timeSelectionId: string, date: string): string => {
    if ((state.selectReservation?.selectedReservations.length || 0) < 2) {
      return ''
    }
    return getTimeSelection(timeSelectionId)
      .reservationsByTime[date].map(reserveId => {
        const reserve = state.selectReservation!.selectedReservations.find(r => r.reservationId === reserveId)
        return t('honorific', { name: reserve?.guestName || reserve?.userName || '-' })
      })
      .join(', ')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!state.hotelId) {
      history.replace({ pathname: '/dashboard' })
      return
    }
    setIsInitializeComponent(true)
    if (!state.selectReservation) {
      setIsFetchedServer(true)
      return
    }
    setIsLoading(true)
    const fetchSalesEndpoint = state.selectReservation.hasSmartCheckin ? fetchCheckinReservationsAndSales : fetchReservationsAndSales
    fetchSalesEndpoint(state.hotelId, state.selectReservation.selectedReservationIds)
      .then(res => {
        const suggestReceiptName =
          state.accommodationInfo?.company || `${state.accommodationInfo?.lastName} ${state.accommodationInfo?.firstName}`
        setPaymentInfo({
          receiptName: state.confirm?.payment?.receiptName || suggestReceiptName,
          usage: res,
        })
        setIsNeedPaymentSelect(!!(state.setting.payment.length && res.totalAmount))
      })
      .finally(() => {
        setIsLoading(false)
        setIsFetchedServer(true)
      })
  }, [])

  if (!isInitializeComponent) {
    return <></>
  }

  return (
    <SelfCheckinLayout>
      <Loading isLoading={isLoading} />
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Please confirm the contents')} />
        <div css={mainStyle}>
          <div css={containerWrapperStyle}>
            <div css={innerStyle}>
              <div css={descriptionStyle}>
                <p>{t('Representative information')}</p>
                <div css={editButtonStyle} onClick={() => goEditPage('accommodation-block')}>
                  <img src={require('@/static/images/edit.svg')} />
                </div>
              </div>
              <div css={itemWrapperStyle}>
                {state.uploadLicense?.identificationFrontImage && (
                  <>
                    <div css={requiredLabelWrapperStyle}>
                      <p css={itemLabelStyle}>{t('Identification')}</p>
                      <RequireText customCss={requiredSymbolStyle} />
                    </div>
                    <div css={identificationWrapperStyle}>
                      <img src={state.uploadLicense.identificationFrontImage} />
                      <img src={state.uploadLicense.identificationBackImage} />
                    </div>
                  </>
                )}
                {state.uploadLicense?.representativePassportImage && (
                  <>
                    <div css={requiredLabelWrapperStyle}>
                      <p css={itemLabelStyle}>{t('Identification')}</p>
                      <RequireText customCss={requiredSymbolStyle} />
                    </div>
                    <div css={identificationWrapperStyle}>
                      <img src={state.uploadLicense.representativePassportImage} />
                    </div>
                  </>
                )}
              </div>
              <>
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Nationality')}</p>
                    <RequireText customCss={requiredSymbolStyle} />
                  </div>
                  <p css={itemTextStyle}>{state.accommodationInfo?.nationality === 'japanese' ? t('Japanese') : t('Non-Japanese')}</p>
                </div>
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Full name')}</p>
                    <RequireText customCss={requiredSymbolStyle} />
                  </div>
                  <p css={itemTextStyle}>
                    {state.accommodationInfo?.lastName} {state.accommodationInfo?.firstName}
                  </p>
                </div>
                {state.accommodationInfo?.nationality === 'japanese' && language === 'ja' && (
                  <div css={itemWrapperStyle}>
                    <div css={requiredLabelWrapperStyle}>
                      <p css={itemLabelStyle}>{t('Furigana')}</p>
                      {state.setting.accommodation.requiredFuriganaInput && <RequireText customCss={requiredSymbolStyle} />}
                    </div>
                    <p css={itemTextStyle}>
                      {state.accommodationInfo?.lastNameFurigana} {state.accommodationInfo?.firstNameFurigana}
                    </p>
                  </div>
                )}
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Date of birth')}</p>
                    {state.setting.accommodation.requiredBirthDateInput && <RequireText customCss={requiredSymbolStyle} />}
                  </div>
                  <p css={itemTextStyle}>{state.accommodationInfo?.dateOfBirth}</p>
                </div>
              </>
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Gender')}</p>
                  {state.setting.accommodation.requiredGenderInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state.accommodationInfo?.gender === 'F' ? t('Female') : t('Male')}</p>
              </div>
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Phone number')}</p>
                  {state.setting.accommodation.requiredTelephoneInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state.accommodationInfo?.phoneNumber}</p>
              </div>
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Email')}</p>
                  {state.setting.accommodation.requiredEmailInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state.accommodationInfo?.mail || '-'}</p>
              </div>
              {!_isOtherOccupation() && (
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Occupation')}</p>
                  <p css={itemTextStyle}>
                    {state.accommodationInfo?.occupation ? t(`${getTranslateOccupation(state.accommodationInfo?.occupation)}`) : '-'}
                  </p>
                </div>
              )}
              {isEmployee(state.accommodationInfo?.occupation) && (
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Company')}</p>
                  <p css={itemTextStyle}>{state.accommodationInfo?.company ? state.accommodationInfo?.company : '-'}</p>
                </div>
              )}
              {_isOtherOccupation() && (
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Occupation')}</p>
                  <p css={itemTextStyle}>{state.accommodationInfo?.otherOccupation ? state.accommodationInfo?.otherOccupation : '-'}</p>
                </div>
              )}
              {state.accommodationInfo?.nationality === 'japanese' && (
                <>
                  <div css={itemWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Post code')}</p>
                    <p css={itemTextStyle}>{state.accommodationInfo?.postCode || '-'}</p>
                  </div>
                </>
              )}
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Address')}</p>
                  {state.setting.accommodation.requiredAddressInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state.accommodationInfo?.address}</p>
              </div>
              <div css={itemWrapperStyle} style={{ borderTop: '1px solid #F2F2F2', paddingTop: 32 }}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Number of guests')}</p>
                  {state.setting.accommodation.requiredAccompanyNameInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>
                  {state.accommodationInfo!.paxTotal}
                  {t(state.accommodationInfo!.paxTotal > 1 ? 'Persons' : 'Person')}
                </p>
              </div>

              {!!accompanyData().length && (
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Accompany name')}</p>
                    {state.setting.accommodation.requiredAccompanyNameInput && <RequireText customCss={requiredSymbolStyle} />}
                  </div>
                  {accompanyData().map((accompany, i) => {
                    return (
                      <p css={itemTextStyle} key={i}>
                        {accompany?.lastName} {accompany?.firstName}
                      </p>
                    )
                  })}
                </div>
              )}
            </div>

            {Boolean(state.checkinCustomize?.guides.length || state.checkinCustomize?.assignSpaces.length) && (
              <div css={innerStyle}>
                <div css={descriptionStyle}>
                  <p>{t('Guidance')}</p>
                  <div css={editButtonStyle} onClick={() => goEditPage('guidance-block')}>
                    <img src={require('@/static/images/edit.svg')} />
                  </div>
                </div>
                {state.checkinCustomize?.guides.map(guide => {
                  return (
                    guide.inputType !== 'none' && (
                      <div key={guide.customCheckinId} css={itemWrapperStyle}>
                        <p css={itemLabelStyle}>
                          {getTitle(guide)}
                          {Boolean(guide.isRequired) && <RequireText />}
                        </p>
                        <p css={itemTextStyle}>{guide.selectedAnswer}</p>
                      </div>
                    )
                  )
                })}

                {Object.keys(state.checkinCustomize?.selectedTime || {}).map((selectionId, i) => {
                  if (!state.checkinCustomize?.selectedTime[selectionId].length) {
                    return <></>
                  }
                  const { selection } = getTimeSelection(selectionId)
                  return (
                    <div key={selectionId} css={itemWrapperStyle}>
                      <p css={itemLabelStyle}>
                        {selection.title[language]}
                        {Boolean(selection.isRequired) && <RequireText />}
                      </p>
                      {state.checkinCustomize?.selectedTime[selectionId]
                        .sort((a, b) => (dayjs(a.day).isAfter(dayjs(b.day)) ? 1 : -1))
                        .map(selectedDate => {
                          const day = dayjs(selectedDate.day).format(t('MM-DD(ddd)'))
                          const guestName = getTargetGuestName(selectionId, selectedDate.day)
                          return (
                            <p key={`${selectionId}-${selectedDate.day}`} css={itemTextStyle}>
                              {`${day} ${selectedDate.time} ${guestName}`}
                            </p>
                          )
                        })}
                    </div>
                  )
                })}
              </div>
            )}

            {isNeedPaymentSelect && paymentInfo && (
              <div css={innerStyle}>
                <div css={descriptionStyle}>
                  <p>{t('Payment details')}</p>
                  <div css={editButtonStyle} onClick={() => goEditPage('payment-detail-block')}>
                    <img src={require('@/static/images/edit.svg')} />
                  </div>
                </div>
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Amount billed')}</p>
                  <p css={itemTextStyle}>¥{paymentInfo.usage.totalAmount.toLocaleString()}</p>
                </div>
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Name of recipient')}</p>
                  <p css={itemTextStyle}>{paymentInfo.receiptName}</p>
                </div>
              </div>
            )}

            {!!(state.selectReservation && !state.selectReservation.hasSmartCheckin) && (
              <div
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', gap: 16 }}
                onClick={() => setIsDeliveryConfirm(!isDeliveryConfirm)}
              >
                <input
                  type="image"
                  alt="checkbox"
                  css={inputCheckboxStyle}
                  src={require(`@/static/images/check-box${isDeliveryConfirm ? '' : '_off'}.svg`)}
                />
                <p>{t('Receive campaign information and special offers by e-mail')}</p>
              </div>
            )}

            <FooterAgreement />
          </div>
          <SelfCheckinFooter
            isDisabled={() => !isFetchedServer}
            goToNextPage={onClickCheckin}
            isNext={isNeedPaymentSelect ? 'next' : 'submit'}
          />
        </div>
      </div>
    </SelfCheckinLayout>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const mainStyle = css({
  padding: '14px 0 32px ',
})

const innerStyle = css({
  maxWidth: 874,
  backgroundColor: '#fff',
  margin: '0 auto 32px',
  padding: '46px 32px 32px 32px',
  borderRadius: 5,
})

const descriptionStyle = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  paddingBottom: 24,
  p: {
    fontSize: 21,
    lineHeight: '27px',
    letterSpacing: '1.8px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
})

const editButtonStyle = css({
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: '#F2F2F2',
  img: {
    width: 30,
  },
  '@media(max-width: 1080px)': {
    width: 46,
    height: 46,
    img: {
      width: 33,
    },
  },
})

const itemWrapperStyle = css({
  width: '100%',
  padding: '0 0 28px',
  '&:last-child': {
    padding: 0,
  },
})

const itemLabelStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '27px',
  marginBottom: 7,
  letterSpacing: '1.8px',
  '-webkit-font-smoothing': 'antialiased',
})

const itemTextStyle = css({
  color: '#272727',
  fontSize: 18,
  lineHeight: '27px',
  letterSpacing: '1.8px',
})

const identificationWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  img: {
    width: 256,
    height: 158,
    borderRadius: 10,
    '&:nth-of-type(1)': {
      margin: '0 20px 0 0',
    },
  },
})

const containerWrapperStyle = css({
  margin: '93px 0',
})

const requiredSymbolStyle = css({
  marginLeft: 4,
  marginBottom: 6,
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '1.4px',
  '@media(max-width: 1080px)': {
    fontSize: 21,
  },
})
const requiredLabelWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const inputCheckboxStyle = css({
  width: 25,
  height: 25,
})
